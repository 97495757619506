<style lang="scss">
.branchenloesung-reise {
  .head-row-text-normal {
    .text-normal {
      display: inline-block;
      text-transform: none !important;
    }
  }
  h2 {
    margin-top: 0;
  }
  ul {
    margin: 10px 0 !important;
    li {
      margin: 5px 0 !important;
    }
  }

  .custom-link-v2 {
    font-weight: 500;
    background: $blue-darker;
    color: #fff !important;
    padding: 10px 15px;
    display: inline-block;
    &:hover {
      opacity: 0.7;
    }
  }
  .preis-box {
    padding: 5px 0;
    margin-bottom: 10px;

    span {
      display: inline-block;
      font-style: italic;
      padding: 8px 10px;
      border-top: 1px solid #eee;
      border-bottom: 3px solid #eee;
      strong {
        display: inline-block;

        font-weight: 400;
        margin-left: 5px;
      }
    }
  }
  .portfolio-box {
    margin-top: 50px;
    [class*='col-'] {
      justify-items: center;
      align-self: center;
    }
  }
  .bild-box {
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .text-center-small {
    @media (max-width: 992px) {
      text-align: center !important;
    }
  }
  .content-link-mehrinfo {
    color: #fff !important;
  }
  .format-box {
    margin-bottom: 20px;
    .col-lg-5,
    .col-lg-3 {
      justify-content: center;
      align-self: center;
    }
    img {
      display: block;
      margin: 0 auto;
      max-height: 120px;
    }
    .tabelle-icon-margin {
      display: inline-block;
      margin: 15px !important;
    }
    .tabelle-box {
      .headline {
        text-transform: none;
        strong {
          display: block;
          text-transform: uppercase;
        }
      }
      .head-row {
        font-size: 80%;
      }
    }
  }
  .alignItems {
    [class*='col-'] {
      justify-content: center;
      align-self: center;
    }
  }
}
.collapsible-container {
  a {
    color: $blue !important;
  }
  img {
    margin: 15px auto;
    max-width: 100%;
    display: block;
  }
  .info-box {
    padding: 10px;
    background: #eee;
    margin: 20px 0;
  }
  .tipp-box {
    margin-bottom: 20px;
    padding: 10px;
    background: $blue-darker;
    color: #fff;
    strong {
      text-transform: uppercase;
    }
    a {
      color: #fff;
      font-weight: 500;
    }
  }
}
</style>

<template>
  <div class="branchenloesung-reise content container">
    <h1>Treten wir gemeinsam die Reise an</h1>
    <p class="intro-txt">
      <strong>Ihre Gäste sind unsere Leserinnen und User.</strong> Nutzen Sie die attraktivste und wichtigste Plattform für touristische Angebote im Alpe-Adria-Raum für Ihre Kommunikationsziele. Jeden Samstag in der Kleinen Zeitung, vier Mal im Jahr als aufmerksamkeitsstarkes Journal „Reisezeit“ in Form eines Mantels und täglich auf
      <a href="https://www.kleinezeitung.at/lebensart/reise/reiseangebote/index.do" target="_blank" class="content-link">kleinezeitung.at/reiseangebote</a>.
    </p>

    <div class="margin-t-s"><h2>Ihre Werbelösungen für Reise</h2></div>

    <TabGroup>
      <Tab tabid="reise-tab1" :title="'Reise am Samstag'" active>
        <div class="row margin-tb-s">
          <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
            <h2>Reise am Samstag</h2>

            <p>
              <strong>Anzeigenschluss / Druckunterlagenschluss: </strong><br />
              Dienstag, 15 Uhr / Donnerstag, 15 Uhr
            </p>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <img src="@/assets/img/img-content//branchenloesungen/reise-am-samstag.png" alt="Reise am Samstag" class="img-fluid" />
          </div>
        </div>

        <div class="text-center">
          <CollapsibleButton link="reise-raum-zeilenanzeigen-tabellen" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Ihre Werbemöglichkeiten</CollapsibleButton>
        </div>
        <Collapsible link="reise-raum-zeilenanzeigen-tabellen">
          <h2>Raumanzeigen</h2>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Millimeterpreise </strong>
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>

                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SA</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">13,33</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">9,43</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">5,77</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3"></div>
          </div>
          <!--<div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Aufschlagseite</strong>
                  Fixformat: 47 x 45 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SA</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">779,81</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">551,66</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">337,55</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x45.svg" alt="47x45" class="img-fluid " />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Aufschlagseite</strong>
                  Fixformat: 98 x 45 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SA</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">1.559,61</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">1.103,31</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">675,09</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x45.svg" alt="98x45" class="img-fluid " />
            </div>
          </div>-->
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Aufschlagseite </strong>
                  Format: 200 x 45 mm <small>(Reservierungspflichtig)</small>
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SA</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">2.399,40</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">1.697,40</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">1.038,60</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x45.svg" alt="200x45" class="img-fluid" />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Aufschlagseite Sonderformat Halbkugel </strong>
                  Format: Balken 200 x 30 mm; Kreis 65 mm Höhe, Radius 103 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SA</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">5.485,97</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">3.880,37</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">2.373,67</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x30-103x65.svg" alt="200x30-103x65" class="img-fluid" />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Innenteil 1/8 Seite</strong>
                  quer 98 x 65 mm / hoch 47 x 135 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SA</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">1.732,90</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">1.225,90</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">750,10</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x65.svg" alt="98x65" class="img-fluid tabelle-icon-margin" />
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x135.svg" alt="47x135" class="img-fluid tabelle-icon-margin" />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Innenteil 1/4 Seite</strong>
                  quer 98 x 135 mm / hoch 47 x 260 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SA</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">3.599,10</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">2.546,10</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">1.557,90</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x135.svg" alt="98x135" class="img-fluid tabelle-icon-margin" />
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x260.svg" alt="47x260" class="img-fluid tabelle-icon-margin" />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Innenteil 1/2 Seite</strong>
                  quer 200 x 135 mm / hoch 98 x 260 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SA</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">7.198,20</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">5.092,20</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">3.115,80</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x135.svg" alt="200x135" class="img-fluid tabelle-icon-margin" />
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x260.svg" alt="98x260" class="img-fluid tabelle-icon-margin" />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Innenteil 1/1 Seite</strong>
                  Format: 200 x 275 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SA</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">14.663,00</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">10.373,00</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">6.347,00</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x275.svg" alt="200x275" class="img-fluid" />
            </div>
          </div>

          <div class="info-txt-preis">Alle Preise in Euro exklusive Werbeabgabe und 20 % USt. Die angeführten Tarife gelten ausschließlich für Unternehmen der Tourismus-Branche.</div>
        </Collapsible>
      </Tab>

      <Tab tabid="reise-tab2" :title="'Reisezeit'">
        <div class="row margin-tb-s">
          <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
            <h2>Reisezeit</h2>
            <p>Das Reisezeit-Cover erscheint vier Mal pro Jahr exklusiv um die Kleine Zeitung. Ansprechende Inhalte – aufmerksamkeitsstark als Mantel um die Zeitung – bieten Ihrem Unternehmen Top-Aufmerksamkeit mit hoher Relevanz für die gesamte Reisebranche. Profitieren Sie von zielgruppengenauen Themenschwerpunkten innerhalb der „Reisezeit“.</p>
            <div class="row">
              <div class="col-lg-5">
                <strong>Erscheinungstage 2022</strong>
                <ul class="content-ul">
                  <li>Samstag, 05. März</li>
                  <li>Samstag, 04. Juni</li>
                  <li>Samstag, 03. September</li>
                  <li>Samstag, 05. November</li>
                </ul>
              </div>
              <div class="col-lg-7">
                <strong>Anzeigenschluss / Druckunterlagenschluss</strong>
                <ul class="content-ul">
                  <li>Mittwoch, 23. Februar</li>
                  <li>Mittwoch, 25. Mai</li>
                  <li>Mittwoch, 24. August</li>
                  <li>Montag, 24. Oktober</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center padding-tb-s">
            <img src="@/assets/img/img-content//branchenloesungen/Titelseite-Reisezeit.jpg" alt="Reisezeit" class="img-fluid shadow img-s-75 img-center" />
          </div>
        </div>

        <div class="text-center">
          <CollapsibleButton link="reisezeit-raum-zeilenanzeigen-tabellen" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Ihre Werbemöglichkeiten</CollapsibleButton>
        </div>
        <Collapsible link="reisezeit-raum-zeilenanzeigen-tabellen">
          <h2>Klassische Inserate</h2>

          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Titelseite</strong>
                  Format: 98 x 45 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SA</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">5.996,70</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">4.241,70</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">2.594,70</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x45.svg" alt="98x45" class="img-fluid " />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Titelseite</strong>
                  Format: 200 x 45 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SA</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">11.993,40</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">8.483,40</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">5.189,40</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x45.svg" alt="200x45" class="img-fluid " />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>U4</strong>
                  Format: 200 x 260 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SA</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">18.023,20</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">12.750,40</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">7.800,00</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x260.svg" alt="200x260" class="img-fluid" />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Innenteil 1/4 Seite</strong>
                  Format: 98 x 135 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SA</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">3.599,10</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">2.546,10</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">1.557,90</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x135.svg" alt="98x135" class="img-fluid " />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Innenteil 1/2 Seite</strong>
                  Format: 200 x 135 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SA</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">7.198,20</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">5.092,20</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">3.115,80</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x135.svg" alt="200x135" class="img-fluid " />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Innenteil 1/1 Seite</strong>
                  Format: 200 x 275 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SA</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">14.663,00</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">10.373,00</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">6.347,00</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x275.svg" alt="200x275" class="img-fluid " />
            </div>
          </div>
          <div class="info-txt-preis text-center">Formate auch in Form von PR-Inseraten buchbar. (Tarif zuzüglich PR-Produktionskostenbeitrag: 1/4 Seite 65,00; 1/2 Seite 125,00; 1/1 Seite 225,00)</div>

          <div class="margin-t-m"></div>
          <h2>PR-Promotionsseite</h2>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Klassisches Inserat + Interviewspalte</strong>
                  Format: 200 x 90 mm + 47 x 170 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center"></div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">10.489,10</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">7.486,10</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">4.667,90</div>
                </div>
                <div class="info-txt-tabelle">Der Rest der ganzen Seite wird mit passendem Content angereichert.</div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x90-47x170.svg" alt="200x90 47x170" class="img-fluid" />
            </div>
          </div>

          <div class="info-txt-preis">Alle Preise in Euro exklusive Werbeabgabe und 20 % USt. Die angeführten Tarife gelten ausschließlich für Unternehmen der Tourismus-Branche.</div>
        </Collapsible>
        <div class="download-pdf">
          <div class="line"><span>DOWNLOAD</span></div>
          <a href="../../pdf/branchenloesungen/Kleine-Zeitung-Reisezeit-2022.pdf" target="_blank">
            <div class="row">
              <div class="col-8 justify-content-center align-self-center">
                Download Reisezeit 2022
              </div>
              <div class="col-4 justify-content-end align-self-center text-right">
                <span>739 kb // PDF</span>
              </div>
            </div>
          </a>
        </div>
      </Tab>
      <Tab tabid="reise-tab3" :title="'Digital'">
        <div class="row margin-t-s margin-b-m">
          <div class="col-lg-12 justify-content-center align-self-center text-center">
            <p>Ob klassisch mit <router-link :to="{ name: 'DisplayWerbung' }" class="content-link">Displaywerbung</router-link> oder native – Ihre Botschaften erreichen Ihre Kunden</p>
          </div>
        </div>
        <div class="row margin-b-m">
          <div class="col-lg-12">
            <h2>Reise-Advertorial &amp; Native Ad</h2>
            <br />
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <h3>Leistungen &amp; Tarif</h3>
            <ul class="content-ul">
              <li>Content-Produktion inkl. Bildersuche</li>
              <li>Korrektorat (2 Korrekturschleifen)</li>
              <li>Anlage des Artikels auf kleinezeitung.at/reise</li>
              <li>Native Ad (Mindestbuchungsvolumen: 500,00*)</li>
              <li>Vorlaufzeit 5 Werktage</li>
            </ul>
            <div class="tabelle-box">
              <div class="row head-row ">
                <div class="col-lg-8 text-align-left hide-small"></div>
                <div class="col-lg-4 text-align-right hide-small">Tarif</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-8 text-align-left ">Reise Advertorial &amp; Native Ad</div>
                <div class="col-lg-6 col-4 text-align-right">790,00</div>
              </div>
              <div class="info-txt-tabelle">*Der TKP ist abhängig vom Buchungsvolumen. Bei einem Buchungsvolumen von 500 Euro beträgt der TKP maximal 19,50 und reduziert sich bei höherem Kampagnenbudget entsprechend.</div>
            </div>
          </div>
          <div class="col-lg-6 align-self-center">
            <img src="@/assets/img/img-content//branchenloesungen/Devices-reise.png" alt="Devices Reise" class="img-fluid" />
          </div>
        </div>

        <div class="row margin-b-m">
          <div class="col-lg-12">
            <h2>Sponsored Story</h2>
            <br />
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <h3>Leistungen &amp; Tarif</h3>
            <ul class="content-ul">
              <li>Ausführliches Briefing und Projektmanagement</li>
              <li>Content Produktion im redaktionellen Look &amp; Feel <small>(zwei Korrekturschleifen inklusive)</small></li>
              <li>Bewerbung auf kleinenzeitung.at, auf der Kleinen Zeitung-APP und in den sozialen Netzwerken</li>
              <li>Reporting der relevanten Kennzahlen</li>
              <li>Inkl. einmalige Integration im Kleine Zeitung-Mittags-Newsletter (für Stmk und Ktn)</li>
              <li>Vorlaufzeit: 5 Werktage</li>
            </ul>
          </div>
          <div class="col-lg-6 align-self-center">
            <div class="tabelle-box">
              <div class="row head-row ">
                <div class="col-lg-8 text-align-left hide-small"></div>
                <div class="col-lg-4 text-align-right hide-small">Tarif</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-8 text-align-left ">3.000 Leserinnen und Lesern</div>
                <div class="col-lg-6 col-4 text-align-right">4.985,00</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-8 text-align-left ">1.500 Leserinnen und Lesern</div>
                <div class="col-lg-6 col-4 text-align-right">3.450,00</div>
              </div>
            </div>
          </div>
        </div>
        <!--<div class="row">
          <div class="col-lg-12">
            <div class="row justify-content-center align-self-center bg-blue-light highlight-box highlight-box-md  margin-b-m">
              <div class="col-md-6 d-flex align-self-center">
                <p>Sie wollen mehr über Native-Werbung wissen? </p>
              </div>
              <div class="col-md-6 d-flex align-self-center justify-content-end">
                <div class="btn-std btn-bg-blue-dark">
                  <router-link :to="{ name: 'Native' }">Hier erfahren Sie mehr!</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>-->
        <div class="row justify-content-center hinweis-box margin-t-s margin-b-m">
          <div class="col-lg-2 bg-left align-self-center"><span class="material-icons">lightbulb</span></div>
          <div class="col-lg-10 bg-right align-self-center">
            <p>Sie wollen mehr über Native-Werbung wissen? <router-link :to="{ name: 'Native' }" class="content-link">Hier erfahren Sie mehr!</router-link></p>
          </div>
        </div>
        <div class="row margin-b-m">
          <div class="col-lg-12">
            <h2>Upgrade: Sponsored Post</h2>
            <br />
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <h3>Leistungen &amp; Tarif</h3>
            <ul class="content-ul">
              <li>Nur in Kombination mit einem Reise-Advertorial buchbar</li>
              <li>Insgesamt 400 garantierte Klicks</li>
            </ul>
          </div>
          <div class="col-lg-6 align-self-center">
            <div class="tabelle-box">
              <div class="row head-row ">
                <div class="col-lg-8 text-align-left hide-small"></div>
                <div class="col-lg-4 text-align-right hide-small">Tarif</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-8 text-align-left ">Upgrade: Sponsored Post</div>
                <div class="col-lg-6 col-4 text-align-right">610,00</div>
              </div>
            </div>
          </div>
        </div>
      </Tab>
    </TabGroup>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
//import Modal from '@/components/Modal.vue';
import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';
import CollapsibleButton from '@/components/util/CollapsibleButton.vue';
import Collapsible from '@/components/util/Collapsible.vue';

export default defineComponent({
  components: {
    //Modal,
    TabGroup,
    Tab,
    CollapsibleButton,
    Collapsible,
  },
});
</script>
